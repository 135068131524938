@import url('https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

/*CSS*/

  .mission {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  color: white;
  }


  /*Our Vision*/
  .ourVision{
    font-family: "PT Sans", sans-serif;
    font-weight: 400;
    font-size: 18px;
    background-color: rgba(255, 255, 255, 20%);
    border-radius: 5%;
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 2%;
    height: 400px;
    width: 650px;
    position: absolute;
    top: 3rem;
    left: 12rem;
    transform: scale(0.8);
    animation: scaleIn 1.5s ease forwards;
  }
  
  @keyframes scaleIn {
    from {
      opacity: 0;
      transform: scale(0.8);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

.missionTitles{
    font-family: "PT Sans", sans-serif;
    font-weight: 700;
    font-size: 35px;
}


/*Our Mission*/


.ourMission{
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-size: 18px;
  background-color: rgba(255, 255, 255, 20%);
  border-radius: 5%;
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 2%;
  height: 400px;
  width: 650px;
  position: absolute;
  bottom: 8rem;
  right: 12rem;
  transform: scale(0.8);
  animation: scaleIn 1.5s ease forwards;
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}



@media only screen and (min-width: 320px) and (max-width: 1720px) {
  .mission {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: auto;
    padding: 5rem 0;
  }

  .ourVision, .ourMission {
    width: 45%;
    position: relative;
    margin: 1rem 0;
    transform: scale(1);
    padding: 2%;
    border-radius: 2%;
  }

  .ourVision {
    top: unset;
    left: unset;
  }

  .ourMission {
    bottom: unset;
    right: unset;
  }
}
