@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

/*CSS*/

  .services{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 88vh;
    text-align: center;
    color: white;
    
  }

  .containerServices{
    font-family: "PT Sans", sans-serif;
    font-weight: 400;
    font-size: 18px;
    background-color: rgba(255, 255, 255, 20%);
    border-radius: 30px;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 0.5%;
    height: 120px;
    width: 450px;
    position: absolute;
    transform: scale(0.8);
    animation: scaleIn 1.5s ease forwards;
  }
  
  @keyframes scaleIn {
    from {
      opacity: 0;
      transform: scale(0.8);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }


  .title{
    font-family: "PT Sans", sans-serif;
    font-weight: 700;
    font-size: 40px;
  }

  .subtitle{
    font-family: "PT Sans", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 18px;
  }