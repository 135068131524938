.navImage {
  max-height: 60px; /* Limita a altura da imagem para manter a proporção */
  width: auto; /* Ajusta a largura automaticamente para manter a proporção */
  vertical-align: middle;
}

.titleRT {
  font-family: "Exo", sans-serif;
  color: #F4EDED; /* Cor do texto do nome da empresa */
  font-size: 24px; /* Ajuste conforme necessário */
  font-weight: 500;
  margin: 0; /* Remove margens padrão do h1 */
  display: inline-block;
  vertical-align: middle;
}

.logo {
  display: flex;
  align-items: center;
}

/* Desktop Screens */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem 2rem;
  box-sizing: border-box;
  background-color: black;
}

/* Melhorando o espaçamento */
.nav-links {
  list-style: none;
  display: flex;
  margin: 0;
}

.nav-links li {
  margin-left: 1rem; /* Reduzindo a margem para menos espaço */
}

.nav-links a {
  color: #F4EDED;
  text-decoration: none;
  font-size: 18px;
  font-weight: 400;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.nav-links a:hover {
  background-color: #444;
}

/* Mobile Screens */
@media (max-width: 768px) {
  .navbar {
      flex-direction: column;
      padding: 0.5rem;
  }

  .nav-links {
      flex-direction: column;
      align-items: center;
      margin-top: 1rem;
  }

  .nav-links li {
      margin: 0.5rem 0;
  }

  .nav-links a {
      font-size: 16px;
  }
}
