@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');


/*CSS*/
.about {
  align-items: center;
  margin: 0 auto;
  padding: 30px;
  text-align: center;
  height: 98vh; /* Ensure the section fills the full viewport height */
  display: flex;
  flex-direction: column;
}

.about p {
  line-height: 1.6; /* Improve readability */
  color: white;
  margin-bottom: 30px; /* Space below paragraph */
  position: relative;
  z-index: 1; /* Keep text on top of the image */
}


.about h2 {
  font-size: 2rem;
  color: white;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
}

.aboutTypography{
  font-weight: 700;
}

.aboutTextBlock {
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-size: 20px;
  background-color: rgba(255, 255, 255, 20%);
  border-radius: 5%;
  padding: 40px;
  height: 600px;
  width: 600px;
  position: absolute;
  top: 30%;
  opacity: 0;
  transform: scale(0.8);
  animation: scaleIn 1.5s ease forwards;
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}




/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .about {
    padding: 15px; /* Adjust padding for smaller screens */
  }

  .about h2 {
    font-size: 1.8rem; /* Reduce heading size on smaller screens */
  }

  .about p {
    font-size: 1rem; /* Reduce paragraph text size */
    position: relative; /* Ensure text stays above the image */
    z-index: 1; /* Keep text on top of the image */
  }
}


