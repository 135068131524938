@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.copyright{
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-size: 10px;
  display: flex;
  justify-content: end;
  box-sizing: border-box;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 40px;
  text-align: end;
  padding-right: 80px;
  padding-top: 15px;

  }