@import url('https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

/*CSS*/

.home {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 88vh;
  text-align: center;
  color: white;
  opacity: 0;
  animation: fadeInSection 2s ease forwards;
}

@keyframes fadeInSection {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/*central text.*/

.homeTextContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(50px);
  animation: slideInText 1.5s ease-out forwards;
}

@keyframes slideInText {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


.homeSubtitles {
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 30px;
  opacity: 0;
  transform: translateY(50px);
  animation: slideInText 1.5s ease-out forwards;
}

@keyframes slideInText {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.homeIcon {
  width: 150px;
  height: auto;
  display: block;
}

.homeContainer {
  top: 33%;  
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  }

.homeTitle {
  font-family: "Exo", sans-serif;
  font-weight: 400;
  font-size: 100px;
  color: white;
}



@keyframes slideInText {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


/*Media Queries*/

/*Mobile*/
@media only screen and (max-width: 600px) {
 
.home {
  padding: 20px;
  height: 45vh;
  font-size: 15px;
}
}

/*Tablets*/
@media only screen and (min-width: 601px) and (max-width: 768px) {

.home {
padding: 30px;
height: 50vh;
font-size: 20px;
}
}

/*Laptops*/
@media only screen and (min-width: 769px) and (max-width: 1024px) {

.home {
padding: 40px;
height: 82vh;
}
.body {
font-size: 18px;
}
}

/*Desktops*/
@media only screen and (min-width: 1025px) and (max-width: 1440px){

.home {
padding: 50px;
height: 78vh;
}

body {
 font-size: 25px;
}
}