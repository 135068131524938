.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 85vh;
  text-align: center;
  color: white;
}

.contactPageImage {
  width: 625px;
  height: auto;
  position: fixed;
  opacity: 30%;
}

.contact h2 {
  font-size: 1.5rem; /* Responsive heading size */
  color: white;
  margin-bottom: 20px; /* Space below heading */
  position: relative;
  z-index: 1; /* Keep text on top of the image */
}

.contact p {
  font-size: 1rem; /* Responsive text size */
  line-height: 1.6; /* Improve readability */
  color: white;
  margin-bottom: 30px; /* Space below paragraph */
  position: relative;
  z-index: 1; /* Keep text on top of the image */
}

.contactBox {
  font-family: "PT Sans", sans-serif;
  font-weight: 500;
  font-size: 20px;
  background-color: rgba(255, 255, 255, 20%);
  border-radius: 5%;
  padding: 40px;
  height: 250px;
  width: 400px;
  opacity: 0;
  transform: scale(0.8);
  animation: scaleIn 1.5s ease forwards;
}

.contactIconLinkedin {
  cursor: pointer;
  color: white;
}
