
/* reset.css */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ul,
ol {
  list-style: none;
}

a {
  text-decoration: none;
}

button,
input,
textarea {
  border: none;
  outline: none;
  background: none;
}

fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  max-width: 100%;
  height: auto;
}

  /*Style CSS*/
body {
  background: linear-gradient(180deg, rgb(0, 0, 0) 15%, rgba(51, 51, 51, 1) 50%, rgba(145, 143, 143, 1) 90%);
    height: 100%;
    margin: 0;
    font-family: sans-serif;
    font-weight: 400;
    font-size: 30px;
    color: white;
    font-family: sans-serif;
    line-height: 1.6;
    
  }

 .backgroundImage {
    top: 15%;
    width: 816px;
    position: fixed;
    height: auto;
    object-fit: cover;
    opacity: 0.2;
    z-index: -1;
    animation: zoomIn 3s forwards;
  } 
  
  @keyframes zoomIn {
    from {
      transform: scale(0.9);
    }
    to {
      transform: scale(1);
    }
  }